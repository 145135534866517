import React from 'react';
import PaymentDetails from './../PaymentDetails';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe} from '@stripe/stripe-js';
import { publishableKey} from './../stripe/config';

const stripePromise = loadStripe(publishableKey);

const Payment = () => {

    return (
        <Elements stripe={stripePromise}>
            <div className="page-container">
                <PaymentDetails />
            </div>
        </Elements>
        
    );
}

export default Payment;